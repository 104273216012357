/**
 * $author tiandd
 * $module iview 颜色变量覆盖
 * $date 2021-06-29
*/

// Color
$primary-color          : #167df0;
$warning-color          : #F7AE48;
$success-color          : #0CB181;
$info-color             : #10aeff;
$success-color-dark     : #3dc19a;
$link-color             : #0CB181;
$error-color            : #e54545;
$json-color             : #900000;
$mask-color             : rgba(0, 0, 0, 0.5);
$white                  : #fff;
$link-hover-color       : #3dc19a;
$link-active-color      : #0CB181;

// Base
$font-family            : "Tahoma For Number","Chinese Quote","-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
$text-color             : #444444;
$text-color-light       : #888888;
$notice-text-color      : #003b80;
$gray-color-light       : #999999;
$text-color-heavy       : #333333;

// Input
$input-disabled-bg      : #f2f2f2;

// Title
$title-color            : #495060;
$subtitle-color         : #7880A3;

// Border
$border-color           : #dddee1;  // outside
$border-color-split     : #e9eaec;  // inside

// shadow
$border-shadow-color    : 0 1px 1px 0 rgba(0,0,0,.1);

// Background
$bg-color       		    : #F9FBFD;
$bg-color-hover         : #EAF1F7;
$bg-color-heavy         : #eee;

// NavBar
$nav-bg-color           : #1e1e29;
$gray-color       		  : #80848f;

// Icon
// $icon-back       		: url('~$/assets/imgs/icon_back$2x.png');
// button

// card