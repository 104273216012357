body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: default;
  background: #FFFFFF;
  font-family: 'PingFangSC-Regular', 'Microsoft YaHei', 'Source Sans Pro', sans-serif;
  user-select: none;
  /* 放开选中复制 */
  -webkit-user-select: unset;
  overflow-x: hidden;
}
ul {
  margin: 0
}
*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.font-medium {
  font-family: 'PingFangSC-Medium', 'Microsoft YaHei';
}
.font-bold {
  font-weight: bold;
}
:focus {
  outline: none;
}
.menu-right {
  justify-content: flex-end;
  min-width: 500px;
}
.brand {
  height: 64px;
  color: #fff;
  span.title {
    font-size: 1.2rem;
  }
}
.brand-platform {
  height: 64px;
  color: #fff;
  span {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: 64px;
    line-height: 64px;
    padding: 0 8px;
    margin-right: 12px;
  }

  span:hover {
    background-color: #1890ff;
    opacity: 0.8;
  }

  .ant-image {
    margin-right: 6px;
  }
}

.brand-multi {
  padding: .5rem;
  height: 64px;
  color: #fff;
  .title-main {
    font-size: 20px;
    line-height: 28px;
    span.title {
      opacity: .5;
    }
  }
  .title-sub {
    font-size: 14px;
    line-height: 14px;
  }
  
}
.brand, .brand-multi {
  float: left;
  .ant-select-dropdown {
    .change {
      display: block;
      text-align: center;
      line-height: 22px;
    }
  }
}
.font-grey {
  color: #7880A3;
}
.font-grey-l2 {
  color: #666;
}
.font-grey-light {
  color: #c0c0c0;
}
.description{
  color: #aaa;
  font-size: 12px;
  margin: .5rem;
}
/* tree 表格 title 样式*/
.table-tree-title {
  display: block;
  overflow: hidden;
  .list-sub-title {
    font-size: 12px;
    color: #a0a0a0;
  }
}

/* 路由样式 */
.page-router {
  margin: 0 0 1rem 0;
}
/* layout style*/
.main-container {
  height: 100vh;
}
.header-blue {
  display: flex;
  justify-content: space-between;
  background: #167df0;
  padding: 0 10px;
}
.header-blue > .menu-blue {
  background: transparent;
}
.global-footer {
  text-align: center;
  background: unset;
  color: rgba(0,0,0,.3);
  position: fixed;
  width: 100%;
  bottom: 0;
}
.margin-r{
  margin-right: 1rem;
}
.icon-btn,
.icon-btn:hover{
  color: #167DF0;
  cursor: pointer;
}
.icon-btn-grey{
  color: #ccc;
  cursor: pointer;
}
.icon-btn-grey:hover{
  color: rgb(131, 131, 131);
  cursor: pointer;
}
.outlink-layout{
  margin: 1rem auto;
  width: 900px;
}
.gs-menu-collapse {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
  .collapse-i {
    padding: 1rem;
    cursor: pointer;
  }
}
/* nodata */
.nodata {
  background: url('../../assets/images/nodata.svg') no-repeat center center;
  background-size: auto 200px;
  width: 200px;
  margin: 6rem auto;
  padding-top: 240px;
  color: #666;
  text-align: center;
}
.nodata.small {
  background-size: auto 100px;
  width: 100px;
  margin: 1rem auto;
  padding-top: 140px;
}
.nodata.mini {
  background-position: top center;
  background-size: 60px auto;
  height: 60px;
  margin: 1rem auto;
  padding-top: 60px;
}

/* step */
.steps-content {
  padding: 2rem 1rem 1rem;
}
.steps-action {
  text-align: center;
}
/*  list */
.list-line-height {
  margin-bottom: .5rem;
}
.list-title {
  text-align: right;
}
/* report */
.report-block {
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 2px 2px 2px #ddd;
  margin-bottom: 1rem;
}
.mh-260 {
  min-height: 250px;
}
.mh-740 {
  height: 714px;
  overflow: auto;
}
.hfix {
  overflow: auto;
}
.report-com {
  color: #21236B;
}
.report-title {
  margin: 0 1rem 0  0;
  line-height: 32px;
  font-size: 18px;
  font-family: 'PingFangSC-Medium', 'Microsoft YaHei';
}
.report-total {
  h3 {
    color: #21236B;
    font-size: 20px;
    margin: 0;
    span{
      font-size: 12px;
      margin-left: 5px;
    }
  }
  h1 {
    font-size: 70px;
    font-family: 'PingFangSC-Medium', 'Microsoft YaHei';
    color: #4F74FF;
    margin: 0;
  }
}
/* 图表 容器 */
.chart-container {
  position: relative;
  z-index: 1;
  min-height: 260px;
  .search-container {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}
/* 单机样式 */
.single-container {
  h3 {
    color: #21236B;
    font-size: 20px;
    margin: 0 0 1rem 0;
    font-family: 'PingFangSC-Medium', 'Microsoft YaHei';
  }
  .tar {
    font-size: 16px;
    line-height: 32px;
    span {
      color: #298EFF;
      margin-left: 5px;
    }
  }
  .footer {
    text-align: right;
    a {
      color: #7F7F7F;
    }
  }
}
.border-blue {
  border-left: 4px solid;
  border-image: linear-gradient(#3363FF , #10DCFF) 1 1; 
}
.border-orange {
  border-left: 4px solid;
  border-image: linear-gradient(#FF8017 , #FFB72A) 1 1; 
}
.border-green {
  border-left: 4px solid;
  border-image: linear-gradient(#8ECE48 , #58C6EF) 1 1; 
}

/* 事件统计 */
.event-container {
  h3 {
    color: #21236B;
    font-size: 20px;
    margin: 0 0 1rem 0;
    font-family: 'PingFangSC-Medium', 'Microsoft YaHei';
  }
  .tar {
    line-height: 1.5rem;
    h4 {
      color: #21236B;
      font-size: 14px;
      margin: 0 0 1rem 0;
    }
    p {
      color: #6D6E9B;
      font-size: 14px;
      margin: 0;
    }
    span {
      color: #298EFF;
    }
  }
}

/* 电量/水量 */
.power-container {
  .tar {
    h4 {
      color: #21236B;
      font-size: 20px;
      margin: 0 0 1rem 0;
      font-family: 'PingFangSC-Medium', 'Microsoft YaHei';
    }
    p {
      color: #6D6E9B;
      font-size: 14px;
      margin: 0;
    }
    span {
      color: #298EFF;
    }
  }
}

/* 设备名片 */
.info-container{
  height: calc(100% - 1rem);
  margin-bottom: 1rem;
  padding: 1rem;
  background: linear-gradient(to bottom right, #192886 , #181A71);
  border-radius: 8px;
  box-shadow: 3px 3px 4px rgb(211, 211, 211);
  h4 {
    color: #8CBCFF;
    font-size: 18px;
  }
  .bright {
    margin-top: 20px;
    p{
      color: #fff;
      margin: 0;
      padding: 0;
      font-size: 21px;
    }
    h1 {
      color: #fff;
      font-size: 72px;
      line-height: 80px;
      margin: 0;
      padding: 0;
    }
  }
  .dark {
    margin-top: 20px;
    p{
      color: #7CAAFF;
      margin: 0;
      padding: 0;
      font-size: 16px;
    }
    h3 {
      color: #fff;
      font-size: 26px;
      line-height: 30px;
      margin: 0;
      padding: 0;
    }
  }
  .equ-img-container {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
}

.my-profile {
  div.ant-typography,
  .ant-typography p {
    margin-bottom: 0;
  }
}
.agreement {
  h3, h4 {
    text-indent: 20px;
  }
  p {
    text-indent: 40px;
  }
}

.loading-container {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 20% 50px;
  text-align: center;
  border-radius: 4px;
}

/* 邀请用户 */
.invite-card {
  width: 700px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: .5rem;
  padding-bottom: 1rem;
}
.invite-card.add {
  margin-bottom: 1rem;
  padding: 1rem 1rem 0 1rem;
  .ant-tabs-nav::before{
    border-bottom: 0;
  }
  .ant-tabs-tab-btn{
    font-size: 16px;
  }
}
.invite-container {
  .header {
    h2, h3 {
      color: #fff;
    }
    h2 {
      font-size: 30px;
      margin: 3rem auto;
    }
    h3 {
      font-weight: normal;
    }
    text-align: center;
    background-color: #057EFE;
    color: #fff;
    border-radius: .5rem;
    padding: 1rem;
  }
  .form {
    padding: 1rem;
  }
}
.adduser-header {
  height: 170px;
  padding: 1rem;
  background: url("../../assets/images/adduser.svg") no-repeat right bottom;
  background-color: #FAFAFA;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  h2 {
    margin-top: 30px;
  }
  h3 {
    color: #8C959F;
  }
}
/* 账号注册 */
.activation-container{
  width: 1200px;
  height: 650px;
  margin: 1rem auto;
  background-color: #fff;
  box-shadow: 0 0 10px #eee;
  display: flex;
  word-break: break-word;

  .left {
    width: 390px;
    background: url("../../assets/res/ad.png") no-repeat top left;
    padding: 1rem;
    position: relative;
    .logo {
      display: block;
      width: 200px;
      height: 50px;
      background: url("../../assets/images/gs-logo.png") no-repeat 100% auto;
    }
    .centext {
      background-color: rgba(42, 112, 191, 0.4);
      text-align: center;
      padding: 1rem 0;
      margin-top: 100px;
      h1, p {
        color: #fff;
        font-weight: normal;
        letter-spacing: 10px
      }
      h1 {
        font-size: 30px;
      }
    }
    .footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 1rem;
      font-size: 12px;
      text-align: center;
      color:#858D99
    }
  }
  .right {
    flex: 1;
    padding: 1rem;
    .steps {
      height: 100%;
      position: relative;
      overflow: hidden;
      .header {
        height: 80px;
      }
      .context {
        height: 460px;
        overflow: auto;
      }
      .agree-console{
        position: absolute;
        bottom: 0.5rem;
        left: 0;
        z-index: 3;
      }
      .footer {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #ddd;
        height: 50px;
        padding-top: 10px;
        text-align: right;
        .ant-btn {
          margin-left: 1rem;
        }
      }
    }
    
  }
}
/* 完善信息版 */
.activation-container.complete {
  height: unset;
  min-height: 650px;
  word-break: break-word;
  .left {
    background: url("../../assets/res/ad2.png") no-repeat top left;
  }
  .right {
    .context {
      height: unset;
      min-height: 460px;
      margin: 40px 0 70px 0;
    }
  }
}
.gs-console-footer {
  text-align: right;
  .ant-btn {
    margin-left: 1rem;
  }
}
.bind-section{
  padding: 1rem 0 1rem 0;
  margin: 0 0 1rem 0;
  background-color: #F6F6F6;
  border: 1px solid #DEDEDE;
  .ant-row {
    margin-bottom: 5px;
  }
}
.site-tag-plus {
  min-width: 150px;
  text-align: center;
  background: #fff;
  border-style: dashed;
}
/* setting */
.setting-bind {
  min-height: 510px;
  .bind-info {
    h1{
      font-size: 18px;
      font-weight: bold;
    }
    p{
      color: #737373;
    }
  }
  .bind-section {
    width: 780px;
    margin: 2rem auto;
    padding: 2rem 0;
  }
}

/* list */
.gs-list-item {
  overflow: hidden;
  min-width: 300px;
  span {
    display: block;
    float: right;
  }
}

/* 移动对话框 */
.move-modal {
  .container{
    min-height: 400px;
    border: 1px solid #d9d9d9;
    .header{
      padding: 1.5rem .5rem;
      height: 180px;
      background: linear-gradient(45deg, #E8A960, #dfa15a);
      text-align: center;
      color: #fff;
      font-size: 18px;
      .anticon {
        font-size: 60px;
      }
      div {
        margin: 10px 0 5px 0;
      }
      p{
        font-size: 14px;
        font-weight: normal;
        margin: 0;
      }
    }
    .search {
      padding: .5rem;
    }
  }
}

/* spin 容器 */
.spin-container {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
}

/* 灰色背景容器 */
.bg-grey-section{
  padding: 1rem;
  margin: 1rem 0;
  background-color: #F6F6F6;
  border: 1px solid #DEDEDE;
}

/* screen小于1400 */
@media screen and (max-width: 1400px) {
  /* 报表统计 */
  .report-total {
    h3 {
      font-size: 16px;
    }
    h1 {
      font-size: 45px;
    }
  }
  .time-container{
    height: 26px;
    margin-top: 3rem;
    .left-line {
      height: 26px;
      .elm {
        height: 26px;
        span{
          width: 26px;
        }
        em {
          top: 10px;
          p {
            min-width: 50px;
            margin-top: 15px;
            padding-left: 15px;
          }
        }
      }
    }
  }
}
/* screen小于1024 */
@media screen and (max-width: 1024px) {
  .login-form-container{
    width: 100%;
    display: block;
    margin: 120px auto;
    
  }
  .login-form-container > div{
    width: 100%;
    display: block;
    flex: 0;
    max-width: unset;
  }
  .login-form-container > div > .login-card{
    // max-width: unset;
    margin: 1rem auto;
  }
  
}

@media screen and (min-width: 1024px) {
  
}